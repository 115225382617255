export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .gjs-hidden-component-boolean {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="icon-lofty-component-block">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3.5l.5-.5h13l.5.5v9l-.5.5h-13l-.5-.5v-9zM14 4H8v3.493h-.5l-3.574-.005 2.09-2.09-.707-.707-2.955 2.955v.708l2.955 2.955.707-.707-2.114-2.114 3.996.005H8v-.986l3.907.005-2.114-2.114.707-.707 2.956 2.955v.708L10.5 11.309l-.707-.707 2.09-2.09L8 8.507V12h6V4z"/></svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelBooleanDynamicComponent}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Componente para variables dinamicas booleanas.', id: 'component-dynamic-boolean-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="booleanDynamicComponent"
        data-gjs-name="Componente Dinamico de Boolean"
        style="padding: 10px"
        data-gjs-resizable="{bc: 1}"
      >
      </div>
    ${style}`,
  });
};
