import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, Button, List,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // maxHeight: 100,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#FFF',
  },
}));

export default function ModalSelectManager({
  model, open, onClose,
}) {
  const classes = useStyle();
  const [optionsRender, setOptionsRender] = useState([]);
  const [labelOption, setLabelOption] = useState('');
  const [valueOption, setValueOption] = useState('');

  useEffect(() => {
    if (model?.attributes?.attributes?.optionsRender) {
      setOptionsRender(model?.attributes?.attributes?.optionsRender);
    }
  });

  function addNewValue() {
    if (labelOption && valueOption) {
      const tempOptions = optionsRender;
      tempOptions.push({value: valueOption, label: labelOption});
      model.addAttributes({optionsRender: tempOptions});
      setOptionsRender(tempOptions.map((index) => index));
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Valor agregado'), 300);
      setLabelOption('');
      setValueOption('');
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Error al agregar, verifique que esten ambos campos con valor'), 300);
    }
  }

  const removeDefaultValue = (value) => {
    // setDefaultValueData((current) => current.filter((data) => data.value !== value));
    const tempOptions = optionsRender;
    const updateState = tempOptions.filter((data) => data.value !== value);
    model.addAttributes({optionsRender: updateState});
    setOptionsRender(updateState.map((index) => index));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Opciones de Seleccion
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{background: '#242424', color: '#fff'}}>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px', height: '400px', overflowY: 'scroll',
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              type="text"
              label="Etiqueta"
              variant="standard"
              value={labelOption}
              onChange={(e) => setLabelOption(e.target.value)}
              style={{backgroundColor: '#D9D9D9', color: '#fff'}}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              type="text"
              label="Valor"
              variant="standard"
              value={valueOption}
              onChange={(e) => setValueOption(e.target.value)}
              style={{backgroundColor: '#D9D9D9', color: '#fff'}}
            />
          </div>
          <Box component="div" className="my-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() => addNewValue()}
              style={{
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: '#29B2EF',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
            >
              Agregar Valor
            </Button>
          </Box>
          <Box component="div" className="my-4">
            <List className={classes.root}>
              {optionsRender.map((option) => (
                <ListItem>
                  <ListItemText
                    primary={`Etiqueta: ${option.label}`}
                    secondary={`Valor: ${option.value}`}
                    primaryTypographyProps={{style: {color: 'gray'}}}
                    secondaryTypographyProps={{style: {color: '#000'}}}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeDefaultValue(option.value)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
