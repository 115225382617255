/* eslint-disable no-console */
import grapejs from 'grapesjs';
import $ from 'jquery';
/* import gjsTuiImageEditor from 'grapesjs-tui-image-editor'; */
import grapesjsStyleBg from 'grapesjs-style-bg';
import toastr from 'toastr';
import gjsBlockBasic from '../pluginsEditor/basicBlocks';
import es from '../util/translator';
import i18nMessageAdd from '../util/i18nMessageAdd';
import 'toastr/build/toastr.min.css';
// import gjsNavbarDefault from '../pluginsEditor/navbarDefault';
// import gjsNavbarCenter from '../pluginsEditor/navbarCenter';
import gjsSwiperDefault from '../pluginsEditor/swiperDefault';
import gjsPdfButton from '../pluginsEditor/exportToPDF';
import gjsAnimatedHamburger from '../pluginsEditor/hamburgerAnimated';
import gjsStandardButton from '../pluginsEditor/standardButton';
import gjsHrefButton from '../pluginsEditor/hrefButton';
import gjsFormInput from '../pluginsEditor/inputForm';
import gjsFormComponent from '../pluginsEditor/formComponent';
import gjsFormCheckbox from '../pluginsEditor/formCheckbox';
import gjsFormTextarea from '../pluginsEditor/formTextArea';
import gjsFormPassword from '../pluginsEditor/passwordInput';
import gjsFormEmail from '../pluginsEditor/emailInput';
import gjsDatabaseComponent from '../pluginsEditor/databaseComponent';
import gjsUserAuthentication from '../pluginsEditor/userAuthComponent';
import gjsFormForgotPassword from '../pluginsEditor/formForgotPassword';
import gjsDynamicImage from '../pluginsEditor/dynamicImage';
import gjsCalendarComponent from '../pluginsEditor/calendarComponent';
import gjsListComponent from '../pluginsEditor/listComponent';
import gjsPayPalComponent from '../pluginsEditor/paypalPaymentButton';
import gjsAnimatedScrollContainer from '../pluginsEditor/animatedScrollContainer';
import gjsModalContainer from '../pluginsEditor/modalComponent';
import gjsThreeCarrusel from '../pluginsEditor/threeCarrusel';
import gjsVariablesComponent from '../pluginsEditor/variablesComponent';
import gjsBooleanDynamicComponent from '../pluginsEditor/booleanDynamicComponent';
import gjsVerifyEmailComponent from '../pluginsEditor/verifyEmailComponent';
import gjsDynamicComponent from '../pluginsEditor/videoCustomComponent';
import gjsPaymentMethodComponent from '../pluginsEditor/paymentMethod';
import gjsVarriableArrayComponent from '../pluginsEditor/variableArrayComponent';
import gjsStripeForm from '../pluginsEditor/stripeForm';
import gjsGoogleIcons from '../pluginsEditor/googleIcons';
import gjsViewCartComponent from '../pluginsEditor/viewCartComponent';
import gjsDropdownMenu from '../pluginsEditor/dropdownMenu';
/* import grapejsBlockBootstrap from 'grapesjs-blocks-bootstrap4';
import grapejsStyleBg from 'grapesjs-style-bg'; */
import gjsCarouselCartComponent from '../pluginsEditor/carouselCartViewer';
import gjsSingleCarouselComponent from '../pluginsEditor/singleDynamicCarousel';
import gjsGoogleLoginComponent from '../pluginsEditor/googleLogin';
import gjsCountdownComponent from '../pluginsEditor/countdownComponent';
import gjsCartItemHandler from '../pluginsEditor/cartItemHandler';
import gjsSelectComponent from '../pluginsEditor/selectComponent';

import {
  addEditorCommand,
  deviceManager,
  layerManager,
  panels,
  scripts,
  selectorManager,
  storageSetting,
  styleManager,
  styles,
  traitManager,
} from './util';

const geditorConfig = ({
  url, token, idProject, idPage, openForm, paypalPlugin, openVariables, openLogic,
  localVars, globalVars, plugins, calendarPlugin, handleInputManager, handleDynamicLinkManager,
  handlePaymentMethodDynamic, handleCustomEndpointManager, handleOpenCarouselCustomEndpointManager,
  handleCustomGoogleIcons, handleViewCartModal, handleRedirectManager, userLogins,
  handleOpenCartManager, handleSelectData,
}) => {
  $('.panel__devices').html('');
  $('.panel__basic-actions').html('');
  $('.panel__editor').html('');
  $('#blocks').html('');
  $('#styles-container').html('');
  $('#layers-container').html('');
  $('#trait-container').html('');

  // Content for Preview
  const navbar = $('#navbar');
  const mainContent = $('#main-content');
  const panelTopBar = $('#main-content > .navbar-light');
  // iniciando grapejs
  const editor = grapejs.init({
    wrapperIsBody: true,
    exportWrapper: true,
    container: '#editor',
    i18n: {
      locale: 'es',
      detectLocale: false,
      localeFallback: 'es',
      messages: {
        es,
      },
      messagesAdd: {
        es: i18nMessageAdd,
      },
    },
    blockManager: {
      appendTo: '#blocks',
    },
    styleManager,
    layerManager,
    traitManager,
    selectorManager,
    panels,
    deviceManager,
    assetManager: {
      upload: `${url}/api/assetsproject/add/${idProject}`,
      openAssetsOnDrop: 1,
      headers: {
        Authorization: token,
      },
      uploadName: 'loftyFiles',
      multiUpload: true,
      credentials: 'omit',
      autoAdd: true,
    },
    storageManager: storageSetting({
      url, idPage, idProject, token,
    }),
    canvas: {
      styles,
      scripts,
    },
    plugins: [
      gjsBlockBasic,
      grapesjsStyleBg,
      gjsSwiperDefault,
      gjsCarouselCartComponent,
      gjsAnimatedHamburger,
      gjsStandardButton,
      gjsHrefButton,
      gjsFormComponent,
      gjsFormInput,
      gjsFormPassword,
      gjsFormEmail,
      gjsFormTextarea,
      gjsFormCheckbox,
      gjsDatabaseComponent,
      gjsUserAuthentication,
      gjsFormForgotPassword,
      gjsDynamicImage,
      gjsCalendarComponent,
      gjsListComponent,
      gjsPayPalComponent,
      gjsAnimatedScrollContainer,
      gjsModalContainer,
      gjsVariablesComponent,
      gjsThreeCarrusel,
      gjsBooleanDynamicComponent,
      gjsVerifyEmailComponent,
      gjsDynamicComponent,
      gjsPaymentMethodComponent,
      gjsVarriableArrayComponent,
      gjsStripeForm,
      gjsGoogleIcons,
      gjsViewCartComponent,
      gjsDropdownMenu,
      gjsSingleCarouselComponent,
      gjsGoogleLoginComponent,
      gjsCountdownComponent,
      gjsPdfButton,
      gjsCartItemHandler,
      gjsSelectComponent,
    ],
    pluginsOpts: {
      [gjsBlockBasic]: {
        idProject,
        token,
        idPage,
        openForm,
        handleDynamicLinkManager,
      },
      gjsTuiImageEditor: {},
      grapesjsBlockBootstrap: {},
      grapesjsStyleBg: {},
      gjsSwiperDefault: {},
      [gjsCarouselCartComponent]: {
        idProject,
        token,
        idPage,
        localVars,
        globalVars,
      },
      gjsAnimatedHamburger: {},
      [gjsStandardButton]: {
        idProject,
        token,
        idPage,
        localVars,
        globalVars,
      },
      [gjsHrefButton]: {
        idProject,
        token,
        idPage,
        handleDynamicLinkManager,
      },
      gjsFormComponent: {},
      [gjsFormInput]: {
        token,
        localVars,
        globalVars,
      },
      gjsFormPassword: {},
      gjsFormEmail: {},
      gjsFormTextarea: {},
      gjsFormCheckbox: {},
      [gjsDatabaseComponent]: {
        idProject,
        token,
        idPage,
        handleInputManager,
        localVars,
        globalVars,
        handleCustomEndpointManager,
        handleRedirectManager,
        handleOpenCartManager,
      },
      [gjsUserAuthentication]: {
        idProject, token, idPage,
      },
      [gjsFormForgotPassword]: {
        token, idPage, idProject,
      },
      [gjsDynamicImage]: {
        idProject, token, idPage,
      },
      [gjsCalendarComponent]: {token, idProject, calendarPlugin},
      gjsListComponent: {},
      [gjsPayPalComponent]: {
        idProject, token, idPage, paypalPlugin,
      },
      gjsAnimatedScrollContainer: {},
      gjsModalContainer: {},
      [gjsVariablesComponent]: {
        localVars,
        globalVars,
        idProject,
        token,
      },
      [gjsThreeCarrusel]: {
        plugins,
        token,
        idProject,
        idPage,
        localVars,
        globalVars,
        handleOpenCarouselCustomEndpointManager,
      },
      [gjsBooleanDynamicComponent]: {
        localVars, globalVars, token, idProject,
      },
      [gjsVerifyEmailComponent]: {token, idPage},
      [gjsDynamicComponent]: {token, idProject, idPage},
      [gjsPaymentMethodComponent]: {
        token,
        idPage,
        idProject,
        handlePaymentMethodDynamic,
        localVars,
        globalVars,
      },
      [gjsVarriableArrayComponent]: {
        localVars,
        globalVars,
      },
      gjsStripeForm: {},
      [gjsGoogleIcons]: {handleCustomGoogleIcons},
      [gjsViewCartComponent]: {handleViewCartModal},
      gjsDropdownMenu: {},
      [gjsSingleCarouselComponent]: {
        token,
        idProject,
        idPage,
        localVars,
        globalVars,
        handleOpenCarouselCustomEndpointManager,
      },
      [gjsGoogleLoginComponent]: {
        token,
        userLogins,
        idPage,
        idProject,
      },
      gjsCountdownComponent: {},
      [gjsPdfButton]: {
        plugins,
        token,
      },
      gjsCartItemHandler: {},
      [gjsSelectComponent]: {
        handleSelectData,
        localVars,
        globalVars,
      },
    },
  });

  // agregando los comandos
  addEditorCommand(editor, openVariables, openLogic);

  // console.log(editor.I18n.getMessages());

  // editor.I18n.setMessages({
  //   es: {
  //     styleManager: {
  //       properties: {
  //         // The key is the property name (or id)
  //         'margin-top': 'Margen Superior',
  //         'margin-right': 'Margen Derecho',
  //         'margin-left': 'Margen Izquierdo',
  //         'margin-bottom': 'Margen Inferior',
  //       },
  //     },
  //   },
  // });

  console.log(editor.StyleManager);

  editor.on('run:preview', () => {
    console.log('It will trigger when we click on preview icon');
    // This will be used to hide border
    editor.stopCommand('sw-visibility');
    // This will hide the sidebar view
    navbar.removeClass('sidebar');
    // This will make the main-content to be full width
    mainContent.removeClass('main-content');

    // This will hide top panel where we have added the button
    panelTopBar.addClass('d-none');
  });

  editor.on('storage:load', (e) => { console.log('Loaded ', JSON.parse(e.assets)); });
  editor.on('storage:store', (e) => { console.log('Stored ', e); });

  editor.on('asset:upload:error', () => {
    toastr.error('Error al cargar Imagen. \n Verifique que sea el formato correcto y su peso sea menor a 1MB.');
  });

  editor.on('asset:upload:response', (response) => {
    const resData = response.data;
    resData.forEach((index) => editor.AssetManager.add(
      {src: index.src, heigth: index.heigth, width: index.width},
    ));
    // editor.AssetManager.add(response);
  });

  editor.on('stop:preview', () => {
    // This event is reverse of the above event.
    console.log('It will trigger when we click on cancel preview icon');
    editor.runCommand('sw-visibility');
    navbar.addClass('sidebar');
    mainContent.addClass('main-content');
    panelTopBar.removeClass('d-none');
  });

  /* setTimeout(() => {
    const categories = editor.BlockManager.getCategories();
    categories.each((category) => category.set('open', false));
  }, 1000); */

  return editor;
};

export default geditorConfig;
