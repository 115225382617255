/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */

export default (editor, opts = {}) => {
  // let counts = 0;
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function addTrait(model, type, answerValue) {
    if (type === 'local') {
      const variableData = opts.localVars;
      const optionData = [];
      variableData.filter((index) => (index.type === 'string'))
        .forEach((variable) => {
          optionData.push({name: `${variable.label}--${variable.type}`, type: variable.type, value: variable._id});
        });
      const traitVariables = {
        type: 'select',
        name: 'localVars',
        label: 'Guardar en',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    } else {
      const variableData = opts.globalVars;
      const optionData = [];
      variableData.filter((index) => (index.typeValue === 'string'))
        .forEach((variable) => {
          optionData.push({name: `${variable.label}--${variable.typeValue}`, type: variable.typeValue, value: variable._id});
        });
      const traitVariables = {
        type: 'select',
        name: 'globalVars',
        label: 'Guardar en',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    }
  }

  function handleButtonManager(model) {
    const {handleSelectData} = opts;
    handleSelectData(model);
  }

  async function optionsManagerButton(model) {
    const managerTrait = {
      name: 'selectManager',
      type: 'button',
      text: 'Manejar Opciones',
      command: () => handleButtonManager(model),
    };
    model.addTrait(managerTrait);
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('selectComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
        this.listenTo(model, 'change:variableHandle', this.searchForVariables);
      },
      async updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('selectComponent')) {
            this.model.removeTrait('globalVars');
            this.model.removeTrait('localVars');
            this.model.removeTrait('selectManager');
            this.model.removeTrait('variableHandle');
            optionsManagerButton(this.model);
            const traitOption = {
              type: 'select', // Type of the trait
              label: 'Tipo de variable', // The label you will see in Settings
              name: 'variableHandle',
              options: [
                {value: 'local', name: 'Local'},
                {value: 'global', name: 'Global'},
              ],
              changeProp: 1,
            };
            this.model.addTrait(traitOption);
            if (this.model.attributes.variableHandle.includes('local')) {
              addTrait(this.model, 'local', this.model.attributes.localVars);
            } else {
              addTrait(this.model, 'global', this.model.attributes.globalVars);
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      // eslint-disable-next-line consistent-return
      async searchForVariables() {
        try {
          if (this.model.attributes.sluglofty.includes('selectComponent')) {
            const getTypeVariable = this.model.getTrait('variableHandle').attributes.value;
            if (getTypeVariable === 'local') {
              this.model.removeTrait('globalVars');
              addTrait(this.model, 'local', '');
            } else {
              this.model.removeTrait('localVars');
              addTrait(this.model, 'global', '');
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
