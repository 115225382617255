export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 24 24" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <path fill-rule="evenodd" d="M3.75 5.5a.75.75 0 000 1.5h10a.75.75 0 000-1.5h-10zm5 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zM5 12a1 1 0 11-2 0 1 1 0 012 0zm-1 7a1 1 0 100-2 1 1 0 000 2z"/><path d="M19.309 7.918l-2.245-2.501A.25.25 0 0117.25 5h4.49a.25.25 0 01.185.417l-2.244 2.5a.25.25 0 01-.372 0z"/>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelComponent}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Componente para seleccion de opciones.', id: 'component-selectcomponent-lofty-editor'},
    content: `
      <select
        data-gjs-slugLofty="selectComponent"
        data-gjs-name="Componente de Seleccion"
        style="padding: 10px;min-width: 50px; min-height: 100px;"
        data-gjs-resizable="{bc: 1}"
      >
      </select>
    ${style}`,
  });
};
